import { ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#7b67f6",
    paper: "#FAFAFA",
    gradient:
      "linear-gradient(90deg, rgba(29,53,134,1) 0%, rgba(123,103,246,1) 50%, rgba(0,238,145,1) 100%)",
  },
  primary: {
    main: "#7b67f6",
  },
  secondary: {
    main: "#888",
    light: "#1d358680",
  },
  error: {
    main: "#ff0019",
  },
  info: {
    main: "#7b67f6",
  },
  warning: {
    main: "#7b67f6",
  },
  success: {
    main: "#00ee91",
  },
  text: {
    primary: "#1d3586",
    secondary: "#1d3586",
  },
};

export default palette;
