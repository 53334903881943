import { ThemeOptions, SimplePaletteColorOptions } from "@mui/material";
import palette from "./palette";

// Due to issues with runtime type checking causing errors when using palette.primary.main / palette.secondary.main from MUI type, had to define again internally.
const palettePrimary = palette.primary as SimplePaletteColorOptions;

const components: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        minWidth: 140,
        boxShadow: "none",
      },
      contained: {
        padding: "0.75rem 1.75rem",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
      text: {
        color: palette.text?.primary,
      },
      sizeLarge: {
        fontSize: "1rem",
        padding: "0.75rem 1.75rem",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        "&:focus-visible, &:focus-visible:hover": {
          outline: `2px solid ${palettePrimary.main}`,
          outlineOffset: "-2px",
          backgroundColor: "#ffffff",
        },
      },
    },
  },
};

export default components;
